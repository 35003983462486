import { useNavigate } from '@remix-run/react';
import { format } from 'date-fns';
import React from 'react';
import { type PopperOptions, usePopperTooltip } from 'react-popper-tooltip';

import { type DtoProduct } from '@lp-lib/api-service-client/public';

import { OptionsIcon } from '../icons/OptionsIcon';

export function ProductsTable(props: {
  title: string;
  subtitle?: string;
  titleAccessory?: React.ReactNode;
  products: DtoProduct[] | null | undefined;
  renderActionSheet?: (product: DtoProduct) => React.ReactNode;
}) {
  const { title, subtitle, titleAccessory, products, renderActionSheet } =
    props;
  const navigate = useNavigate();
  return (
    <div>
      <div className='pb-4'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-bold'>
            {title}{' '}
            <span className='text-icon-gray text-xl'>
              ({products?.length ?? 0})
            </span>
          </h2>
          {titleAccessory}
        </div>
        {subtitle && <div className='text-sm text-icon-gray'>{subtitle}</div>}
      </div>
      {!products || products.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No products
        </div>
      ) : (
        <table className='w-full table-fixed border-collapse'>
          <thead>
            <tr>
              <th className='text-left font-bold pl-3 py-2 border-b border-t border-secondary'>
                Name
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary'>
                Description
              </th>
              <th className='text-left font-bold py-2 border-b border-t border-secondary'>
                Created
              </th>
              {renderActionSheet && (
                <th className='text-left font-bold py-2 border-b border-t border-secondary w-12' />
              )}
            </tr>
          </thead>
          <tbody>
            {products.map((product) => (
              <tr
                key={product.id}
                className='hover:bg-dark-gray cursor-pointer h-full'
                onClick={() => navigate(`/admin/products/${product.id}`)}
              >
                <td className='text-left font-bold pl-3 py-3 border-b border-black-001 pr-3 truncate'>
                  {product.name}
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001 pr-3 truncate'>
                  {product.description ?? ''}
                </td>
                <td className='text-left text-icon-gray py-3 border-b border-black-001'>
                  {format(new Date(product.createdAt), 'MMM dd, yyyy')}
                </td>
                {renderActionSheet && (
                  <td className='h-px p-0 border-b border-black-001'>
                    <div className='h-full flex items-center justify-center'>
                      <ProductActionSheet
                        actionSheet={renderActionSheet(product)}
                      />
                    </div>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export function ProductActionSheet(props: {
  actionSheet: React.ReactNode;
  placement?: PopperOptions['placement'];
  disabled?: boolean;
}) {
  const { actionSheet, placement, disabled } = props;
  const { getTooltipProps, setTooltipRef, setTriggerRef, visible } =
    usePopperTooltip({
      trigger: 'click',
      placement: placement ?? 'auto',
    });

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <div>
      <button
        className={`
          btn flex flex-row justify-center items-center
          text-white rounded-lg
          hover:bg-light-gray p-2
          transition-colors
        `}
        onClick={onClick}
        ref={setTriggerRef}
        type='button'
        disabled={disabled}
      >
        <OptionsIcon className='w-3.5 h-3.5 fill-current' />
      </button>
      {visible && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({
            className: `
              w-auto h-auto max-h-full
              border border-[#303436] rounded-lg
              text-white flex flex-col p-1 z-50
              transition-opacity bg-black whitespace-nowrap
            `,
          })}
        >
          {actionSheet}
        </div>
      )}
    </div>
  );
}

export function ProductActionSheetMenuItem(props: {
  icon: React.ReactNode;
  text: string;
  onClick: () => void;
  className?: string;
  disabled?: boolean;
}) {
  const { icon, text, onClick, disabled } = props;
  const handleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClick();
  };
  return (
    <button
      className={`
        btn w-full h-8 px-2 hover:bg-dark-gray rounded-lg
        flex items-center gap-2 text-3xs
        ${props.className ?? ''}
      `}
      onClick={handleClick}
      type='button'
      disabled={disabled}
    >
      {icon}
      {text}
    </button>
  );
}

export function ProductActionSheetMenu(props: { children: React.ReactNode }) {
  return <div className='flex flex-col gap-1'>{props.children}</div>;
}
