import { useNavigate } from '@remix-run/react';
import pluralize from 'pluralize';
import { useMemo, useState } from 'react';
import {
  FormProvider,
  useFieldArray,
  useForm,
  useFormContext,
  useWatch,
} from 'react-hook-form';
import Select, { type SingleValue } from 'react-select';

import {
  type DtoProduct,
  type EnumsProductBillingInterval,
} from '@lp-lib/api-service-client/public';

import { useLiveAsyncCall } from '../../hooks/useAsyncCall';
import { useInstance } from '../../hooks/useInstance';
import { useLiveCallback } from '../../hooks/useLiveCallback';
import { apiService } from '../../services/api-service';
import { err2s } from '../../utils/common';
import { buildReactSelectStyles } from '../../utils/react-select';
import { DragDropList } from '../common/DragDrop';
import { SegmentedControl } from '../common/SegmentedControl';
import { type Option } from '../common/Utilities';
import {
  ConfirmCancelModalHeading,
  ConfirmCancelModalText,
  useAwaitFullScreenConfirmCancelModal,
} from '../ConfirmCancelModalContext';
import { FilledCheckIcon } from '../icons/CheckIcon';
import { PlusIcon } from '../icons/PlusIcon';
import { Loading } from '../Loading';
import { ProductCard } from './ProductCard';
import { useBillingIntervalOptions } from './useBillingIntervalOptions';
import { usePublishedProducts } from './useProducts';

function getOptionLabel(option: DtoProduct): string {
  const numPrices = option.prices?.length ?? 0;
  return `${option.name} – ${numPrices} ${pluralize('price', numPrices)}`;
}

function ProductPicker(props: {
  onSelect: (product: DtoProduct) => void;
}): JSX.Element | null {
  const { onSelect } = props;
  const exclude = useWatch<MarketedProductsFormData, 'marketed'>({
    name: 'marketed',
  });

  // note(falcon): not using an async select component because there's no search really.
  const { data, isLoading, error } = usePublishedProducts();
  const options = useMemo(() => {
    if (!data) return [];
    const excludedProductIds = new Set(exclude.map((p) => p.id));
    return data.filter((product) => !excludedProductIds.has(product.id));
  }, [exclude, data]);
  const styles = useInstance(() => buildReactSelectStyles<DtoProduct>());

  const handleChange = (option: SingleValue<DtoProduct>) => {
    if (!option) return;
    onSelect(option);
  };

  if (isLoading) {
    return (
      <div className='w-full flex items-center justify-center'>
        <Loading text='' />
      </div>
    );
  }

  if (error) {
    return (
      <div className='w-full flex items-center justify-center'>
        <p className='text-red-001'>{err2s(error)}</p>
      </div>
    );
  }

  return (
    <Select<DtoProduct, false>
      placeholder='Select product...'
      styles={styles}
      classNamePrefix='select-box-v2'
      className='w-full'
      value={null} // never set.
      options={options}
      getOptionValue={(option) => option.id}
      getOptionLabel={getOptionLabel}
      onChange={handleChange}
      isSearchable
    />
  );
}

type MarketedProductsFormData = {
  marketed: DtoProduct[];
};

function EditableHeader(props: {
  onCancel: () => void;
  onSave: (data: MarketedProductsFormData) => Promise<void>;
}) {
  const { onCancel, onSave } = props;
  const triggerModal = useAwaitFullScreenConfirmCancelModal();

  const {
    call: submit,
    state: { state, error },
    reset,
  } = useLiveAsyncCall(onSave);

  const {
    handleSubmit,
    formState: { isSubmitSuccessful, isDirty },
  } = useFormContext<MarketedProductsFormData>();

  const onSubmit = useLiveCallback(handleSubmit(submit));

  const handleCancel = async () => {
    if (!isDirty) {
      onCancel();
      return;
    }

    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <ConfirmCancelModalText className='mt-4 text-sms font-normal'>
            You have unsaved changes. Are you sure you want to discard them?
          </ConfirmCancelModalText>
        </div>
      ),
      cancelBtnLabel: 'Keep editing',
      confirmBtnLabel: 'Discard changes',
      confirmBtnVariant: 'delete',
    });
    if (result === 'confirmed') {
      onCancel();
    }
  };

  const handleSave = async () => {
    const { result } = await triggerModal({
      kind: 'confirm-cancel',
      prompt: (
        <div className='px-5 py-2 text-white'>
          <ConfirmCancelModalHeading>Are you sure?</ConfirmCancelModalHeading>
          <div className='mt-4 text-base font-normal'>
            Please review before proceeding.
            <ul className='py-2 space-y-1'>
              <li className='list-disc'>
                Newly added products will be marketed to users via the paywall.
              </li>
              <li className='list-disc'>
                Removed products will no longer be marketed via the paywall, but
                will remain published.
              </li>
            </ul>
            This is an operation that has <em>monetary</em> impact!
          </div>
        </div>
      ),
      cancelBtnLabel: 'Cancel',
      confirmBtnLabel: 'Publish',
      confirmBtnVariant: 'delete',
    });
    if (result === 'confirmed') {
      onSubmit();
    }
  };

  return (
    <div>
      <div className='pb-4 mb-4 border-b border-secondary'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-bold'>Marketed</h2>
          <div className='flex items-center gap-5'>
            <div className='text-icon-gray text-sms'>
              {isSubmitSuccessful && !isDirty && (
                <div className='flex items-center gap-1'>
                  <FilledCheckIcon />
                  Saved
                </div>
              )}
            </div>

            <button
              type='button'
              onClick={handleCancel}
              disabled={state.isRunning}
              className='btn-secondary w-33 h-8 text-sm'
            >
              {isDirty ? 'Cancel' : 'Close'}
            </button>
            <button
              type='button'
              onClick={handleSave}
              disabled={state.isRunning || !isDirty}
              className='btn-primary w-33 h-8 text-sm'
            >
              {state.isRunning ? 'Saving...' : 'Save'}
            </button>
          </div>
        </div>
        <div className='text-sm text-icon-gray'>
          Published products marketed via the paywall.
        </div>
      </div>
      {error && (
        <div className='w-full px-5 py-2 text-base flex gap-1 bg-lp-black-002'>
          <p className='text-red-005'>Error: {err2s(error)}</p>
          <button
            type='button'
            onClick={() => reset()}
            className='btn self-end text-primary'
          >
            Clear
          </button>
        </div>
      )}
    </div>
  );
}

function EditableMarketedProducts(props: {
  initialData: DtoProduct[];
  setIsEditing: (value: boolean) => void;
  onSaved?: (published: DtoProduct[]) => void;
  billingIntervalOptions: Option<EnumsProductBillingInterval>[];
  billingInterval: Option<EnumsProductBillingInterval>;
  setBillingInterval: (value: Option<EnumsProductBillingInterval>) => void;
}) {
  const {
    initialData,
    setIsEditing,
    onSaved,
    billingIntervalOptions,
    billingInterval,
    setBillingInterval,
  } = props;

  const form = useForm<MarketedProductsFormData>({
    defaultValues: {
      marketed: initialData ?? [],
    },
  });

  const {
    fields: marketedProducts,
    append,
    remove,
    move,
  } = useFieldArray<MarketedProductsFormData, 'marketed', 'key'>({
    control: form.control,
    name: 'marketed',
    keyName: 'key',
  });

  const handleAddProduct = (product: DtoProduct) => {
    append(product);
  };

  const handleSave = async (data: MarketedProductsFormData) => {
    await apiService.product.updateMarketedProducts({
      productIds: data.marketed.map((product) => product.id),
    });
    onSaved?.(data.marketed);
    form.reset(data);
  };

  const handleMove = (fromIndex: number, toIndex: number) => {
    move(fromIndex, toIndex);
  };

  return (
    <div>
      <FormProvider {...form}>
        <EditableHeader
          onCancel={() => setIsEditing(false)}
          onSave={handleSave}
        />
        <div className='flex flex-col items-center gap-8'>
          {billingIntervalOptions.length > 1 && (
            <div className='w-min'>
              <SegmentedControl
                options={billingIntervalOptions}
                value={billingInterval}
                onChange={(v) => setBillingInterval(v)}
              />
            </div>
          )}

          <div className='flex flex-wrap gap-4'>
            <DragDropList
              type='published-products'
              items={marketedProducts}
              onMove={handleMove}
              render={({ item, index, drag, ref, style }) => {
                return (
                  <div ref={ref} style={style}>
                    <div ref={drag} className='cursor-move'>
                      <ProductCard
                        product={item}
                        onClickDelete={() => remove(index)}
                        noHoverEffect
                        disabled
                        billingInterval={billingInterval.value}
                      />
                    </div>
                  </div>
                );
              }}
            />

            <div className='flex-none w-75 h-130 flex flex-col items-center justify-center gap-4 rounded-2xl bg-lp-black-004 border-secondary border'>
              <div className='flex items-center justify-center gap-1 text-primary'>
                <PlusIcon />
                <strong>Add Product</strong>
              </div>
              <div className='w-full px-10'>
                <ProductPicker onSelect={handleAddProduct} />
              </div>
            </div>
          </div>
        </div>
      </FormProvider>
    </div>
  );
}

function ReadOnlyMarketedProducts(props: {
  products: DtoProduct[];
  setIsEditing: (value: boolean) => void;
  billingIntervalOptions: Option<EnumsProductBillingInterval>[];
  billingInterval: Option<EnumsProductBillingInterval>;
  setBillingInterval: (value: Option<EnumsProductBillingInterval>) => void;
}) {
  const {
    products,
    setIsEditing,
    billingIntervalOptions,
    billingInterval,
    setBillingInterval,
  } = props;
  const navigate = useNavigate();

  return (
    <div>
      <div className='pb-4 mb-4 border-b border-secondary'>
        <div className='flex justify-between items-center'>
          <h2 className='text-2xl font-bold'>Marketed</h2>
          <button
            type='button'
            className='btn flex justify-center items-center text-primary gap-1 text-sm hover:underline'
            onClick={() => setIsEditing(true)}
          >
            Edit
          </button>
        </div>
        <div className='text-sm text-icon-gray'>
          Published products marketed via the paywall.
        </div>
      </div>
      {products.length === 0 ? (
        <div className='w-full py-6 flex items-center justify-center text-icon-gray'>
          No products
        </div>
      ) : (
        <div className='flex flex-col items-center gap-8'>
          {billingIntervalOptions.length > 1 && (
            <div className='w-min'>
              <SegmentedControl
                options={billingIntervalOptions}
                value={billingInterval}
                onChange={(v) => setBillingInterval(v)}
              />
            </div>
          )}

          <div className='relative flex flex-wrap gap-4 isolate'>
            {products.map((product) => (
              <ProductCard
                key={product.id}
                product={product}
                onClick={() => navigate(`/admin/products/${product.id}`)}
                disabled
                billingInterval={billingInterval.value}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export function MarketedProductsEditor(props: {
  initialData: DtoProduct[];
  onChange?: () => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const billingIntervalOptions = useBillingIntervalOptions(props.initialData);
  const [billingInterval, setBillingInterval] = useState(
    billingIntervalOptions[0]
  );

  return isEditing ? (
    <EditableMarketedProducts
      initialData={props.initialData}
      setIsEditing={setIsEditing}
      onSaved={props.onChange}
      billingIntervalOptions={billingIntervalOptions}
      billingInterval={billingInterval}
      setBillingInterval={setBillingInterval}
    />
  ) : (
    <ReadOnlyMarketedProducts
      products={props.initialData}
      setIsEditing={setIsEditing}
      billingIntervalOptions={billingIntervalOptions}
      billingInterval={billingInterval}
      setBillingInterval={setBillingInterval}
    />
  );
}
